import React from "react";

import { Link, NavLink } from "react-router-dom";

function Features() {
  return (
    <>
      <div className="features-area section-bg fix long-padding pt-110">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center mb-60">
                <h2>
                  Search &amp; Verify <br />
                  Information
                </h2>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="features-box text-center mb-30">
                <div className="features-svg">
                  <svg
                    width="70.837"
                    height="78.921"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 511.999 511.999"
                    xmlSpace="preserve"
                  >
                    <rect
                      x="226.501"
                      y="48.781"
                      transform="matrix(-0.8092 -0.5875 0.5875 -0.8092 500.1945 529.8347)"
                      style={{ fill: "#CDD4D5" }}
                      width="219.247"
                      height="269.842"
                    />
                    <g>
                      <path
                        style={{ fill: "#F0353D" }}
                        d="M423.507,171.717c-1.611,0-3.235-0.49-4.638-1.509l-95.531-69.366
                                                c-3.533-2.566-4.319-7.509-1.753-11.043c2.567-3.533,7.509-4.317,11.042-1.752l95.531,69.366c3.534,2.566,4.319,7.509,1.753,11.042
                                                C428.364,170.585,425.952,171.717,423.507,171.717z"
                      />
                      <path
                        style={{ fill: "#F0353D" }}
                        d="M383.871,226.306c-1.611,0-3.235-0.49-4.638-1.509l-95.531-69.366
                                                c-3.534-2.566-4.319-7.509-1.753-11.043c2.567-3.533,7.509-4.317,11.043-1.752l95.531,69.366c3.533,2.566,4.319,7.509,1.753,11.043
                                                C388.727,225.174,386.315,226.306,383.871,226.306z"
                      />
                      <path
                        style={{ fill: "#F0353D" }}
                        d="M344.232,280.895c-1.611,0-3.235-0.49-4.638-1.509l-95.531-69.366
                                                c-3.533-2.566-4.318-7.509-1.752-11.043c2.565-3.533,7.508-4.318,11.043-1.752l95.53,69.366c3.534,2.566,4.319,7.509,1.753,11.043
                                                C349.09,279.763,346.678,280.895,344.232,280.895z"
                      />
                    </g>
                    <rect
                      x="27.696"
                      y="61.665"
                      transform="matrix(-0.9876 0.1567 -0.1567 -0.9876 303.7421 369.2175)"
                      style={{ fill: "#E6EAEA" }}
                      width="219.241"
                      height="269.835"
                    />
                    <g>
                      <path
                        style={{ fill: "#F0353D" }}
                        d="M68.441,147.119c-3.82,0-7.18-2.775-7.798-6.668c-0.684-4.312,2.257-8.363,6.569-9.047
                                                l116.599-18.502c4.312-0.689,8.363,2.257,9.047,6.569c0.685,4.312-2.257,8.363-6.569,9.047L69.689,147.019
                                                C69.269,147.087,68.852,147.119,68.441,147.119z"
                      />
                      <path
                        style={{ fill: "#F0353D" }}
                        d="M79.013,213.748c-3.82,0-7.18-2.775-7.798-6.668c-0.684-4.312,2.257-8.363,6.569-9.047
                                                l116.599-18.502c4.308-0.688,8.362,2.257,9.047,6.569c0.684,4.312-2.257,8.363-6.569,9.047L80.263,213.649
                                                C79.843,213.715,79.426,213.748,79.013,213.748z"
                      />
                      <path
                        style={{ fill: "#F0353D" }}
                        d="M89.586,280.376c-3.82,0-7.18-2.775-7.798-6.668c-0.684-4.312,2.257-8.363,6.569-9.047
                                                l116.599-18.502c4.31-0.686,8.362,2.257,9.046,6.569c0.684,4.312-2.257,8.363-6.569,9.047L90.835,280.277
                                                C90.416,280.343,89.998,280.376,89.586,280.376z"
                      />
                    </g>
                    <rect
                      x="109.098"
                      y="161.919"
                      style={{ fill: "#E6EAEA" }}
                      width="219.251"
                      height="269.848"
                    />
                    <rect
                      x="109.098"
                      y="161.919"
                      style={{ fill: "#CDD4D5" }}
                      width="109.626"
                      height="269.848"
                    />
                    <g>
                      <path
                        style={{ fill: "#F0353D" }}
                        d="M277.754,237.285H159.696c-4.366,0-7.906-3.54-7.906-7.906c0-4.366,3.54-7.906,7.906-7.906h118.058
                                                c4.367,0,7.906,3.54,7.906,7.906C285.66,233.745,282.121,237.285,277.754,237.285z"
                      />
                      <path
                        style={{ fill: "#F0353D" }}
                        d="M277.754,304.746H159.696c-4.366,0-7.906-3.54-7.906-7.906c0-4.366,3.54-7.906,7.906-7.906h118.058
                                                c4.367,0,7.906,3.54,7.906,7.906C285.66,301.207,282.121,304.746,277.754,304.746z"
                      />
                      <path
                        style={{ fill: "#F0353D" }}
                        d="M277.754,372.208H159.696c-4.366,0-7.906-3.54-7.906-7.906s3.54-7.906,7.906-7.906h118.058
                                                c4.367,0,7.906,3.54,7.906,7.906S282.121,372.208,277.754,372.208z"
                      />
                    </g>
                    <path
                      style={{ fill: "#365558" }}
                      d="M378.947,422.805c-2.023,0-4.047-0.772-5.591-2.316l-33.731-33.731c-3.087-3.087-3.087-8.093,0-11.18
                                            c3.088-3.087,8.093-3.087,11.181,0l33.731,33.731c3.087,3.087,3.087,8.093,0,11.18C382.994,422.033,380.97,422.805,378.947,422.805z
                                            "
                    />
                    <circle
                      style={{ fill: "#4ACFD9" }}
                      cx="286.186"
                      cy="322.141"
                      r="92.76"
                    />
                    <circle
                      style={{ fill: "#E6EAEA" }}
                      cx="286.186"
                      cy="322.141"
                      r="59.029"
                    />
                    <rect
                      x="401.346"
                      y="403.572"
                      transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 1035.076 479.594)"
                      style={{ fill: "#FF8C29" }}
                      width="33.731"
                      height="101.192"
                    />
                    <g>
                      <path
                        style={{ fill: "#0295AA" }}
                        d="M281.97,355.343c-2.023,0-4.047-0.772-5.591-2.316c-3.087-3.087-3.087-8.093,0-11.18l21.082-21.082
                                                c3.088-3.087,8.093-3.087,11.181,0c3.087,3.087,3.087,8.093,0,11.18l-21.082,21.082
                                                C286.017,354.571,283.993,355.343,281.97,355.343z"
                      />
                      <path
                        style={{ fill: "#0295AA" }}
                        d="M271.43,323.72c-2.023,0-4.047-0.772-5.591-2.316c-3.087-3.087-3.087-8.093,0-11.18l23.19-23.19
                                                c3.088-3.087,8.093-3.087,11.181,0c3.087,3.087,3.087,8.093,0,11.18l-23.19,23.19C275.476,322.948,273.452,323.72,271.43,323.72z"
                      />
                    </g>
                    <path
                      d="M508.737,132.552L331.322,3.73c-1.696-1.231-3.81-1.741-5.884-1.41c-2.07,0.329-3.927,1.466-5.159,3.162L242.908,112.04
                                            l-10.653-67.129c-0.328-2.071-1.466-3.926-3.163-5.159c-1.697-1.232-3.815-1.738-5.884-1.41L6.668,72.706
                                            c-4.312,0.684-7.253,4.735-6.569,9.046L42.39,348.265c0.329,2.071,1.466,3.927,3.162,5.159c1.359,0.987,2.986,1.508,4.645,1.508
                                            c0.412,0,0.826-0.033,1.239-0.098l49.757-7.896v84.826c0,4.366,3.54,7.906,7.906,7.906H328.35c4.367,0,7.906-3.54,7.906-7.906
                                            V409.47c7.702-4.423,14.79-9.83,21.104-16.143c0.003-0.003,0.005-0.005,0.008-0.007c19.013-19.014,29.484-44.293,29.484-71.181
                                            c0-2.593-0.107-5.219-0.311-7.84L510.49,143.595C513.056,140.06,512.271,135.117,508.737,132.552z M16.954,87.083L217.88,55.199
                                            l11.891,74.935l-17.337,23.876H109.099c-4.366,0-7.906,3.54-7.906,7.906v39.151v68.306v61.556l-44.428,7.05L16.954,87.083z
                                            M117.005,423.859v-86.179v-68.306v-68.305v-31.245h99.445c0.002,0,0.004,0.001,0.006,0.001c0.003,0,0.006-0.001,0.008-0.001h26.352
                                            h63.269h14.358v8.26v49.379c-10.927-3.974-22.402-5.988-34.258-5.988c-29.842,0-57.968,13.13-77.165,36.025
                                            c-9.183,10.951-15.809,23.464-19.695,37.19c-2.526,8.924-3.806,18.16-3.806,27.451c0,16.108,3.694,31.5,10.976,45.751
                                            c0.002,0.004,0.003,0.007,0.005,0.012c17.312,33.866,51.678,54.903,89.684,54.903c11.864,0,23.339-2.011,34.258-5.978v7.032
                                            L117.005,423.859L117.005,423.859z M346.188,382.14c-0.001,0.001-0.002,0.002-0.003,0.003c-6.308,6.308-13.52,11.556-21.433,15.601
                                            c-12.005,6.137-24.98,9.249-38.565,9.249c-32.039,0-61.01-17.737-75.606-46.289c-0.001-0.002-0.002-0.004-0.003-0.006
                                            c-6.135-12.003-9.245-24.976-9.245-38.559c0-7.835,1.079-15.623,3.209-23.145c3.273-11.563,8.858-22.107,16.598-31.337
                                            c16.185-19.301,39.894-30.372,65.048-30.372c13.569,0,26.545,3.116,38.564,9.263c25.594,13.089,42.713,37.895,45.788,66.358
                                            c0.001,0.005,0.002,0.011,0.002,0.017c0.331,3.074,0.5,6.174,0.5,9.216C371.041,344.805,362.215,366.112,346.188,382.14z
                                            M382.541,292.895c-7.362-24.39-23.782-45.177-46.285-58.078v-56.734v-16.166c0-4.366-3.539-7.906-7.906-7.906h-22.263h-63.269
                                            h-10.844l12.514-17.234c0.001-0.001,0.002-0.002,0.003-0.004l83.939-115.603L493.051,140.7L382.541,292.895z"
                    />
                    <path
                      d="M286.187,255.204c-36.908,0-66.935,30.027-66.935,66.935s30.027,66.935,66.935,66.935s66.935-30.027,66.935-66.935
                                            S323.095,255.204,286.187,255.204z M286.187,373.262c-28.19,0-51.123-22.934-51.123-51.123s22.934-51.123,51.123-51.123
                                            c28.19,0,51.123,22.934,51.123,51.123S314.376,373.262,286.187,373.262z"
                    />
                    <path
                      d="M399.956,400.876c-1.483-1.483-3.494-2.316-5.591-2.316c-2.097,0-4.107,0.833-5.591,2.316l-23.851,23.851
                                            c-3.087,3.087-3.087,8.093,0,11.181l71.554,71.554c1.544,1.544,3.567,2.316,5.591,2.316c2.023,0,4.047-0.772,5.591-2.316
                                            l23.852-23.852c1.482-1.482,2.315-3.493,2.315-5.59c0-2.097-0.833-4.108-2.315-5.59L399.956,400.876z M442.068,490.691
                                            l-60.373-60.374l12.671-12.671l60.374,60.374L442.068,490.691z"
                    />
                  </svg>
                </div>
                <div className="features-text">
                  <h4>Search</h4>
                  <p>
                    Type in relevant keywords or criteria for the desired
                    information. This could include names of
                    individuals,company/organization name,Land Reference Number,
                    vehicle Registration number, or any other specific number
                    e.g company registartion number.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="features-box text-center mb-30">
                <div className="features-svg">
                  <svg
                    width="70.837"
                    height="78.921"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.34"
                      d="M8.38086 12.0001L10.7909 14.4201L15.6209 9.58008"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.7509 2.44982C11.4409 1.85982 12.5709 1.85982 13.2709 2.44982L14.8509 3.80982C15.1509 4.06982 15.7109 4.27982 16.1109 4.27982H17.8109C18.8709 4.27982 19.7409 5.14982 19.7409 6.20982V7.90982C19.7409 8.29982 19.9509 8.86982 20.2109 9.16982L21.5709 10.7498C22.1609 11.4398 22.1609 12.5698 21.5709 13.2698L20.2109 14.8498C19.9509 15.1498 19.7409 15.7098 19.7409 16.1098V17.8098C19.7409 18.8698 18.8709 19.7398 17.8109 19.7398H16.1109C15.7209 19.7398 15.1509 19.9498 14.8509 20.2098L13.2709 21.5698C12.5809 22.1598 11.4509 22.1598 10.7509 21.5698L9.17086 20.2098C8.87086 19.9498 8.31086 19.7398 7.91086 19.7398H6.18086C5.12086 19.7398 4.25086 18.8698 4.25086 17.8098V16.0998C4.25086 15.7098 4.04086 15.1498 3.79086 14.8498L2.44086 13.2598C1.86086 12.5698 1.86086 11.4498 2.44086 10.7598L3.79086 9.16982C4.04086 8.86982 4.25086 8.30982 4.25086 7.91982V6.19982C4.25086 5.13982 5.12086 4.26982 6.18086 4.26982H7.91086C8.30086 4.26982 8.87086 4.05982 9.17086 3.79982L10.7509 2.44982Z"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="features-text">
                  <h4>Verify</h4>
                  <p>
                    Upon inputing please, click "Continue" and thereafter verify
                    by clicking "I am sure" button and click "Continue". You
                    will be taken to the payment page and upon completion of
                    payment your search excerpt will be generated.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="features-box text-center mb-30">
                <div className="features-svg">
                  <svg
                    width="70.837"
                    height="78.921"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 392.53 392.53"
                    xmlSpace="preserve"
                  >
                    <path
                      style={{ fill: "#FFFFFF" }}
                      d="M361.016,257.552c5.301,0,9.632-4.331,9.632-9.632V31.418c0-5.301-4.331-9.632-9.632-9.632H31.706
                      c-5.301,0-9.632,4.331-9.632,9.632v164.073h71.693c6.012,0,10.925,4.848,10.925,10.925v51.071h78.352
                                            c-2.651-8.145-4.073-16.679-4.073-25.665c0-46.158,37.56-83.717,83.782-83.717c46.158,0,83.782,37.56,83.782,83.717
                                            c0,8.986-1.487,17.648-4.073,25.665h18.683v0.065H361.016z"
                    />
                    <path
                      style={{ fill: "#56ACE0" }}
                      d="M108.183,235.766h70.788c-0.065-1.293-0.129-2.521-0.129-3.879c0-46.158,37.56-83.717,83.782-83.717
                      c46.158,0,83.782,37.56,83.782,83.717c0,0,0,0,0,0.065c1.616-1.939,2.65-4.331,2.65-7.046V54.497
                      c0-6.012-4.848-10.925-10.925-10.925H54.656c-6.012,0-10.925,4.848-10.925,10.925v130.198c0,3.426,1.616,6.659,4.331,8.727"
                    />

                    <g>
                      <path
                        style={{ fill: "#194F82" }}
                        d="M316.797,82.23H75.925c-6.012,0-10.925-4.848-10.925-10.925c0-6.077,4.848-10.925,10.925-10.925
                        h240.937c6.012,0,10.925,4.848,10.925,10.925C327.787,77.382,322.874,82.23,316.797,82.23z"
                      />
                      <path
                        style={{ fill: "#194F82" }}
                        d="M316.797,128.259H75.925c-6.012,0-10.925-4.848-10.925-10.925c0-6.012,4.848-10.925,10.925-10.925
                                                h240.937c6.012,0,10.925,4.848,10.925,10.925C327.723,123.41,322.874,128.259,316.797,128.259z"
                      />
                      <path
                        style={{ fill: "#194F82" }}
                        d="M174.575,174.416H75.925c-6.012,0-10.925-4.848-10.925-10.925c0-6.012,4.848-10.925,10.925-10.925
                                                h98.651c6.012,0,10.925,4.848,10.925,10.925C185.436,169.503,180.587,174.416,174.575,174.416z"
                      />
                      <path
                        style={{ fill: "#194F82" }}
                        d="M361.016,0H31.706C14.317,0,0.159,14.158,0.159,31.418v164.137c0,10.214,4.848,20.04,13.059,26.117
                                                l68.073,51.071c5.624,4.267,12.606,6.594,19.653,6.594h92.703c6.788,9.891,15.774,18.23,26.053,24.436v77.705
                                                c-0.259,8.857,10.602,15.127,18.36,7.887l24.436-23.273l24.436,23.273c8.404,7.499,19.006-0.259,18.36-7.887v-77.705
                                                c10.343-6.206,19.265-14.545,26.053-24.436h29.608c17.39,0,31.418-14.158,31.418-31.418V31.418C392.563,14.158,378.405,0,361.016,0
                                                z M82.777,246.626l-39.046-29.285h39.046V246.626z M283.634,356.008l-13.576-12.865c-4.202-4.008-10.796-4.008-15.063,0
                                                l-13.511,12.8v-43.055c6.723,1.745,13.77,2.715,21.01,2.715c7.24,0,14.287-0.905,21.01-2.715v43.055h0.129V356.008z
                                                    M262.559,293.754c-34.133,0-61.867-27.798-61.867-61.867s27.798-61.867,61.867-61.867c34.133,0,61.867,27.798,61.867,61.867
                                                S296.757,293.754,262.559,293.754z M370.777,247.855c0,5.301-4.331,9.632-9.632,9.632h-18.877
                                                c2.65-8.145,4.073-16.679,4.073-25.665c0-46.158-37.56-83.717-83.782-83.717s-83.782,37.56-83.782,83.717
                                                c0,8.986,1.487,17.648,4.073,25.665h-78.287v-51.071c0-6.012-4.848-10.925-10.925-10.925H21.945V31.418
                                                c0-5.301,4.331-9.632,9.632-9.632h329.438c5.301,0,9.632,4.331,9.632,9.632v216.436H370.777z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ fill: "#FFC10D" }}
                        d="M241.549,312.954v43.119l13.511-12.8c4.202-4.008,10.796-4.008,15.063,0l13.511,12.8v-43.119
                                                c-6.723,1.745-13.77,2.715-21.01,2.715C255.319,315.604,248.272,314.634,241.549,312.954z"
                      />
                      <path
                        style={{ fill: "#FFC10D" }}
                        d="M262.559,170.02c-34.133,0-61.867,27.798-61.867,61.867s27.798,61.867,61.867,61.867
                                                c34.133,0,61.867-27.798,61.867-61.867S296.757,170.02,262.559,170.02z"
                      />
                    </g>
                    <polygon
                      style={{ fill: "#FFFFFF" }}
                      points="82.777,217.341 43.731,217.341 82.777,246.626 "
                    />
                  </svg>
                </div>
                <div className="features-text">
                  <h4>Download Certificate</h4>
                  <p>
                    You can now proceed to download your search excerpt or send
                    it to your email.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
